.loading-container{
	// margin-left: -1rem;
    text-align: center;
	margin-top: 10%;
}
footer{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	color: #f5f5f5;
	padding: 10px 0;
	text-align: center;
	font-size: 13px
}
.login-logo{
    margin-top: 30px;
	// margin-left: -1rem;
    width:160px;
    text-align: center;
}
.loading-gif{
    /* margin-top: 260px; */

}
