.humans-percentage-container {
	grid-column: 1/3;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
	background:url("../../../../../../components/images/human-image.svg") white    ;
	background-repeat: no-repeat;
	background-position:  100% 100%;
	position: relative;
}
.humans-percentage-content{
	padding:0px 29px
}
.humans-percentage-value{
	color: rgba(102, 45, 145, 1);
	font-weight: bolder;
	font-size: 3.3vw;
	margin-top:10px
}
.humans-values{
	font-size: 1vw;
	margin-top:10px;
	margin-bottom: 10px;
}
.positive-growth-rate{
	margin-left:7px;
	font-size: 1.5vw;
	color:rgba(73, 182, 91, 1);
	margin-top:10px
}
.negative-growth-rate{
	font-size: 1.5vw;
	margin-left:7px;
	color:red;
	margin-top:10px
}