.add-website-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.add-website-modal-content {
	min-height: 35vh;
	background-color: white;
	border-radius: 20px;
	padding: 10px 20px;
	overflow-y: hidden;
	min-width:  30vw;
	display: flex;
	flex-direction: column;
	/* box-sizing: border-box; */
}

.add-website-form{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.add-website-close-modal {
    width: 1.5vw;
    height: 1.5vw;
    text-align: center;
    justify-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 50%;
	cursor: pointer;
}

.add-website-close-modal--button{
	padding: 0;
	margin: 0 10%;
}

.add-website-close-modal h3 {
	font-family: "Montserrat";
}

.add-website-close-modal:hover {	
	background-color: rgb(231, 226, 226);	
}

.add-website-modal-header{
	display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.add-website-button-box{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 20%;
	
}

.add-website-alias-expanding-input{
	display: flex;
    flex-direction: row;
    align-items: center;
	width: 100%;
	/* height: 100%; */
}

.add-website-alias-expanding-input .input-group{
    width: 79%;
    /* padding: 3vw; */
	/* padding: 1% 3vw 0 1%; */
}

.add-website-alias-expanding-input h3{
	/* margin: 0; */
}
.add-website-input{
	box-sizing: border-box;
	background: #F4F4F4;
	border-radius: 12px;
	height: 40px;
	border: none;
	padding-left: 14px;
	font-family: "Montserrat";
	width: 528px;
	margin: 7px 0px;
	align-items: center;
	/* Center the default value */
	padding: 0 12px;
	
}

.add-website-scroll-inputs{
	height: 18vh;
	overflow-y: scroll;
	margin-bottom: 1vh;
	box-sizing: border-box;
}

.confirm-add-website-button{
	padding: 8px 16px;
	border-radius: 8px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
	align-self: flex-end;
	width: 20%;
	min-width: 60px;
	margin-top: auto;
}

.confirm-add-website-button-disable{
	opacity: 0.6;
	padding: 8px 16px;
	border-radius: 8px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: not-allowed;
}

.confirm-add-website-button:hover {
	background-color: #fff;
	color: #662D91;
	transition: all 0.5s ease
}
.add-website-alias-first-button{
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.add-website-modal-body{
    display: flex;
    justify-content: space-around;
    height: 80%;
    padding: 5%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
}

