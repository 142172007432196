.inc-ind {
    span {
        font-weight: bold;

        &::after {
            font-size: 14px;
            vertical-align: text-top;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
    }

    .negative {
        color: red;
        display: flex;
        gap: 2px;
        align-items: center;
        flex-direction: row;
    }

    .positive {
        color: green;
        display: flex;
        gap: 2px;
    }
}