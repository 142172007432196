.footer{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	color: #f5f5f5;
	padding: 10px 0;
	text-align: center;
	font-size: 13px;
	z-index: -1;
}