.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: calc(30.33vh - 40px) calc(30.33vh - 40px);
    height: 100%;
    width: 100%;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding-bottom: 2em;

    .graph {
        background-color: #fff;
        box-shadow: 2px 2px 10px 0px #e3e2e2;
        margin: 1em 0 0;
        border-radius: 10px;
        position: relative;
    }

    .item-1 {
        grid-row: 1/ span 2;
        grid-column: 1/3;
    }

    .item-3 {
        grid-row: 1/ span 2;
        grid-column: 3/6;
    }

    .item-4 {
        grid-row: 1/ span 2;
        grid-column: 6/9;
    }

    .item-5 {
        grid-row: 3;
        grid-column: 1/9;
        display: flex;
        gap: 20px;
        justify-content: space-between;
    }

    .item-6 {
        grid-row: 4;
        grid-column: 1/9;
    }
    .tooltip {
        margin: 7px 7px 0 0;
    }
}
