.currentActivity-graphs-section {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: calc(50.33vh - 20px);
	gap: 20px;
	width: 100%;
	height: 100%;
}

.visitedBots-container {
	grid-column: 9/-1;
	background-color: #fff;
	border-radius: 8px;
	background: url("../../../images/botActivity.png") white right;
	background-repeat: no-repeat;
	background-position: 97% 75%;
	padding: 0px 29px;
	background-size: 12vw;
}

.protect-mode {
	grid-column: 1/7;
	background-color: #fff;
	border-radius: 8px;
	background: url("../../../images/bots-image.svg") white right;
	background-repeat: no-repeat;
	background-position: 97% 75%;
	padding: 0px 29px;
	background-size: 15vw;
}

.currentBots-container {
	grid-column: 1/5;
	background-color: #fff;
	border-radius: 8px;
	padding: 1rem;
	position: relative;
	.maliciousBots-section {
		display: flex;
		gap: 1rem;
		justify-content: space-between;

		.lastMins-bots :nth-child(1),
		.today-bots :nth-child(1) {
			font-weight: bolder;
			font-size: 1vw;
		}

		.lastMins-bots :nth-child(2),
		.today-bots :nth-child(2) {
			font-weight: bolder;
			font-size: 2vw;
			margin-top: 0.5rem;
			color: #662D91;
		}
	}

	.traffic-section {
		font-weight: bolder;
		height: 100%;
		margin-top: 1rem;
		position: relative;
	}
}

.currentBots-container.Detected {
	background: url("../../../images/human-image.svg") white right;
	background-repeat: no-repeat;
	background-position: 100% -20%;
	padding: 0px 0px 0px 20px;
	background-size: 15vw;
}

.current-activity-graphs {
	grid-column: 1/5;
	background-color: #fff;
	grid-row: 1/1;
	border-radius: 8px;
	padding: 7px 7px 15px 0;
	font-size: 18px;
	font-weight: 500;
}

.currentBots-container p,
.protect-mode p,
.visitedBots-container p {
	width: 59%
}

.active-sessions-graphs {
	grid-column: 5/9;
	background-color: #fff;
	border-radius: 8px;
	grid-row: 1/1;
	border-radius: 8px;
	padding: 7px;
	font-size: 18px;
	font-weight: 500;
	position: relative;
}

.active-sessions-graphs-protect-mode {
	grid-column: 7/-1;
	background-color: #fff;
	border-radius: 8px;
	grid-row: 1/1;
	border-radius: 8px;
	padding: 7px 7px 15px 0;
	font-size: 18px;
	font-weight: 500;
}

.nivo-legend text {
	white-space: pre-wrap;
	overflow-wrap: break-word;
	color: red;
}

.active-page-table-container {
	padding: 0px 7px 15px 0;
	display: flex;
	justify-content: center;
	border-radius: 8px;
	width: 100%;
}

.active-pages::-webkit-scrollbar {
	width: 0.2em
}

.active-pages::-webkit-scrollbar-thumb {
	width: 0.2em;
}

.active-pages {
	overflow: hidden;
    overflow-y: scroll;
    max-height: 88%;
    font-size: 1rem;
    margin-top: 1.2rem;
	// padding-left: 15px;
	margin-left: 3px;
}

.active-page-table th {
	position: sticky;
	top: 0;
	background: white;
}

.active-pages th {
	position: sticky;
	top: 0;
	background: white;
}

.todayPie-legend-section {
	position: absolute;
	top: 4rem;
	right: 0.5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
}

.legends-values {
	display: flex;
	align-items: center;
	font-size: 0.8vw;
	gap: 0.5rem
}

.legend-keys {
	content: "";
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 20%;
}