.toolTipContent {
    .humans {
        color: #68B465;
    }

    .visits {
        color: #DA764A;
    }

    .clicks {
        color: #5F2F8C;
    }

    text-align: left;
    cursor: pointer;
    padding:6px 13px 6px 13px;
    gap:4px;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 0px rgba(31, 31, 31, 0.2);
}

.alignCenter {

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}