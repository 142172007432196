.mfa-modal-section {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 1;
}

.mfa-modal-content {
	/* height: 45vh; */
	background-color: white;
	border-radius: 20px;
	padding: 10px 20px;
	overflow-y: auto;
	min-width:  30vw;
	position: relative;
	overflow: hidden;
}

.modal-name {
	font-size: 1.1vw;
}

.mfa-modal-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
body.active-modal {
	overflow-y: hidden;
	background-color: gray;
}
.mfa-qr{
	display: flex;
	flex-direction: column;
    text-align: center;
}
.mfa-setup-input{
	text-align: center;
	background-color: #F4F4F4;
    color: black;
    outline: none;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    line-height: 43px;
    width: 75%;
    margin-top: 18px;
    padding: 2px 8px;
}

.mfa-setup-form{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.mfa-setup-button{
    background-color: #662D91;
    padding: 13px 16px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    border: 1px solid #662D91;
    margin-top: 14px;
}

.mfa-setup-button:hover{
	background-color: #fff;
    border: 1px solid #662D91;
    color: #662D91;
    transition: all 0.5s ease
}
.mfa-modal-error-display{
	background-color: red;
	width: 100%;
	height: 10%;
	position: absolute;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.mfa-modal-error-display span{
	color: white
}
.mfa-setup-image-container{
	min-height: 152px;
}