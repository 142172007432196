.dataprocessing-container{
    text-align: center;
    margin: auto auto;
    display: flex
;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
}