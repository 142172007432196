.feature-section{
    padding:12px 24px;
	background-color: #fff;
	grid-column: 2/3;
	grid-row:1/2;
	border-radius:8px;
}
.feature-link{
	text-decoration: none;
	color: black;
}
.feature-link:hover{
	color:#662D91
}