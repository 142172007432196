.content-div--LicenceKey {
	grid-column: 1/3;
	height: fit-content;
	padding: 10px 20px 15px 20px;
	overflow-x: scroll;
	margin-bottom: 50px;

	.loader {
		font-size: 4px;
		margin: 0 auto;
		margin-bottom: 5%;
	}

	;
}

;

.licence-keys-table {
	width: 100%;
	white-space: nowrap;

	& p {
		line-height: 2px;
	}

	;
}

;

.licenceKeys-typeTag {
	width: 106px;
	border-radius: 13px;
	padding: 3px 12px;
	text-align: center;
	color: white;
	font-weight: 700;
	font-size: 13px;
	line-height: 19.5px;

	&--Trial {
		background-color: #662D91;
	}

	;

	&--Active {
		background-color: rgba(73, 182, 91, 1);
	}

	;

	&--Expired {
		background-color: rgba(216, 216, 216, 1);
		color: rgba(90, 90, 90, 1);
	}

	;

	&--Cancelled {
		background-color: rgba(226, 41, 55, 1);
	}

	;

	&--Upcoming {
		background-color: rgba(219, 240, 222, 1);
		color: rgba(73, 182, 91, 1);
	}

	;

}

;

.inf {
	font-size: 26px;
}