.mydetails-content {
    background-color: #fff;
    padding: 10px 32px 15px 20px;
    ;
    border-radius: 8px;
    /* margin-top: 67px; */
    grid-row: 2/3;
    grid-column: 1/2;
}

@media (max-width:1300px) {

    .name-email-input,
    .url-password-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px !important;
    }

    .save-changes {
        float: left !important;
        margin-top: 10px;
    }

    .full-name,
    .email,
    .url,
    .password,
    .company-name {
        margin-top: 10px;
    }

    .eye-icon {
        position: absolute;
        top: 86.5% !important;
        left: 338px;
        cursor: pointer;
        transform: translateY(-50%);
    }

}

.add-mfa {
    float: right;
    background-color: #662D91;
    padding: 13px 16px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    border: 1px solid #662D91;
    margin-top: 14px;
    margin-right:15px;
    width: 162px
}

.remove-mfa{
    float: right;
    background-color: red;
    padding: 13px 16px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    border: 1px solid red;
    margin-top: 14px;
    margin-right:15px
}

.change-password {
    text-decoration: none;
    float: left;
    background-color: #F4F4F4;
    padding: 13px 16px;
    color: #1F1F1F;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    border: 1px solid #1F1F1F;
    margin-top: 14px;
    max-width: fit-content;
}

.change-password:hover{
    background-color: #662D91;
    border: 1px solid #662D91;
    color: #F4F4F4;
    transition: all 0.5s ease
}

.add-mfa:hover {
    background-color: #fff;
    border: 1px solid #662D91;
    color: #662D91;
    transition: all 0.5s ease
}

//STYLING REDEFINED IN REGION.SCSS

// .save-change:disabled {
//     background-color: #fff;
//     border: 1px solid #662D91;
//     color: #662D91;
//     transition: all 0.5s ease
// }

// .save-change {
//     float: right;
//     background-color: #662D91;
//     padding: 13px 16px;
//     color: #fff;
//     border-radius: 8px;
//     cursor: pointer;
//     font-family: "Montserrat";
//     font-size: 16px;
//     border: 1px solid #662D91;
//     margin-top: 14px;
//     // margin-right:15px;
//     width: 160px
// }

.full-name,
.email,
.url,
.password,
.company-name {
    display: flex;
    flex-direction: column;
}

.name-email-input,
.url-password-input {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 22px;
    flex-direction: column;
}

.email{
    margin-bottom: 50px;
}

.company-input {
    display: flex;
    justify-content: space-around;
    align-items: center
}

.inputs {
    box-sizing: border-box;
    background: #F4F4F4;
    border-radius: 12px;
    line-height: 40px;
    border: none;
    padding-left: 14px;
    font-family: "Montserrat";
    width: 528px;
    outline: none;
}

.eye-icon {
    position: absolute;
    right: 27px;
    top: 68.5%;
    cursor: pointer;
    transform: translateY(-50%);
}