.active-campaign-panel{
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 0px #e3e2e2;
}

.active-campaign-panel::-webkit-scrollbar {
    width: 0.2em;
    background-color: transparent;
}