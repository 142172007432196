.adn-main {
    border-radius: 5%;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    
    .option{
        padding: .5em 1em;
        border:2px solid  #2A7FB0;
        border-radius: 6px;
        color: #2A7FB0;
        background-color: white;
        &:nth-child(even){
            margin: 0 8px;
        }
        &:hover{
            background-color: rgb(204, 227, 236);
        }
        &.selected{
            font-weight: bold;
            background-color: #2A7FB0;
            color:white;
        }
    }
}