.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 3;
}
.max-icon{
    display: flex;
    /* width: max-content; */
    position: absolute;
    right: 61px;
    color: hsl(0, 0%, 87%);
    cursor: pointer;
    z-index: 2;
}

.modal-header  h2{
    color: #662D91;
}
.cancel-btn{
    font-size: 2vh;
	padding:6px 12px
}
.cancel-btn:hover{
	background-color: #dedede;
	padding:6px 12px;
	cursor:pointer;
	border-radius: 10%;
}