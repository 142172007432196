.edit-user-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 5;
}

.edit-user-modal-content {
	/* height: 45vh; */
	background-color: white;
	border-radius: 8px;
	padding: 0px 20px;
	overflow-y: auto;
	min-width:  30vw;
}
.edit-user-modal-status-text{
	text-align: center;
}

.edit_user-confirm-button{
	padding: 8px 16px;
	width: 70px;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
	float: right;
	margin-top: 15px
}

.edit_user-confirm-button:hover{
	background-color: #fff;
	color: #662D91;
	// transition: all 0.5s ease;
}

.edit_user-confirm-button--cant{
	opacity: 0.6;
	cursor: not-allowed;
}

.edit_user-confirm-button--cant:hover{
	background-color: #662D91;
	color: #fff;
}

.edit-user-cancel{
	padding: 8px 16px;
	width: 90px;
	margin-right: 12px;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid black;
	background-color: #fff;
	color: black;
	font-family: "Montserrat";
	cursor: pointer;
	float: right;
	margin-top: 15px
}

.edit-user-cancel:hover{
	background-color: black;
	color: #fff;
	// transition: all 0.5s ease;
}


.website-checklist > *{
	cursor: pointer;
}

.modal-name {
	color: #662D91;
	font-weight: 700;
}

.modal-toggle-button {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
}

.modal-toggle-button input[type="checkbox"] {
    display: none;
}

.modal-toggle-button .modal-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: #d63030;
    border-radius: 17px;
    cursor: pointer;
    // transition: background-color 0.2s;
}

.modal-toggle-button .modal-slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.modal-toggle-button.active .modal-slider {
    background-color: #662D91;
}

.modal-toggle-button.active .modal-slider:before {
    transform: translateX(18px);
}

.modal-toggle-button.inactive .modal-slider {
    background-color: #ccc;
}

.modal-toggle-button label {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

// .modal-toggle-button input[type="checkbox"]:checked {
//     transform: translateX(26px);
// }