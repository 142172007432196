.finishedSessions-graphs-section{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: calc(55.33vh - 20px) calc(50.3vh - 20px) calc(50.3vh - 20px);
    gap: 20px;
    width: 100%;
    height: 100%;
}
.finishedSessions-heatMap-graph{
    grid-column: 1/7;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 1/1;
}
.today-country-lineGraph{
    grid-column: 1/7;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 2/3;
    position: relative;
}
.today-referral-linegraph{
    grid-column: 7/-1;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 2/3;
    position: relative;
}
.today-browser-lineGraph{
    grid-column: 1/7;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 3/-1;
    position: relative;
}
.today-OS-lineGraph{
    grid-column: 7/-1;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 3/-1;
    position: relative;
}
.referral-sources-graph{
    grid-column: 7/-1;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 1/1;
}
.finishedSessions-barGraph{
    grid-column: 9/-1;
    background-color: #fff;
    border-radius: 8px;
    grid-row: 1/1;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}
.today-Geography-data{
	grid-column: 1/9;
    background-color: #fff;
    border-radius: 8px;
    grid-row: 1/3;
    padding: 7px 7px 15px 0;
    font-size: 18px;
    font-weight: 500;
    grid-row: 1/2;
    position: relative;
}
.today-country-table{
	grid-column: 9/-1;
    grid-row: 1/2;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    font-weight: 500;

}
.today-zoom-tooltip {
    margin-top: auto;
    position: absolute;
    width: 99%;
    text-align: right;
    color: #c2c2c2;
    font-size: smaller;
    font-weight: 200;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.4s;
    margin-left: auto;
}
.today-hide-toolTip {
    opacity: 0;
    display: none;
}
.today-zoom-controls-parent {
    width: 98.5%;
    position: absolute;
    display: flex;
    align-items: right;
    flex-direction: column;
    margin-left: auto;
    z-index: 2;
    margin-top: -55px;
}
.zoom-controls {
    text-align: center;
    font-family: Playfair Display;
    font-size: larger;
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-top: auto;
    background-color: #f4f4f4;
    border: solid 1px #c2c2c2;
    color: #4b4b4b;
    border-radius: 5px;
    margin-top: 5px;
}
.zoom-controls:hover {
    filter: brightness(95%);
}
.zoom-controls:active {
    filter: brightness(92%);
}
.zoom-controls.zoom-in-btn {
    background-image: url(../../../images/zoom-in.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.zoom-controls.zoom-out-btn {
    background-image: url(../../../images/zoom-out.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.transform-component-module_wrapper__SPB86 {
    width: 100% !important;
    margin-left: 3px !important;
}