.websites {
    background-color: #fff;
    padding: 10px 20px 15px 20px;
    border-radius: 8px;
    grid-row: 2/2;
    grid-column: 1/-1;
}

.websites-view-btn{
    padding: 8px 16px;
    margin-left: 10px;
    border-radius: 8px;
    font-family: "Montserrat";
    background-color: #fff;
    color: #662D91;
    border: 1px solid #662D91;
    cursor: pointer;
    margin-right: 6px;
    font-size: 16px;
    border: 1px solid #662D91;
}

.websites-view-btn:hover{
    background-color: #662D91;
    border: 1px solid #662D91;
    color: #fff;
    transition: all 0.5s ease;
}