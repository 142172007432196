.tooltip {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.tooltip>svg {
    border-radius: 8px;
    // border: 1px solid #1f1f1f;
}

.tooltip .tooltiptext {
    width: 145px;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    transform: translate(-50%, 0);
    padding: 8px 10px;
    top: 90%;
    left: 50%;
    right: 0%;
    /* margin-left: -60px; */
    position: absolute;
    z-index: 1;
    font-size: 14px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}