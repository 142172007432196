.breadcrum {
    text-decoration: none;
    color: black;
    max-width: 55%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    &:first-of-type{
        &::before{
            content: ' ';
        }
    }
    &::before{
        content: ' > ';
    }
    &:hover{
        font-weight: 700;
        &::before{
            font-weight: 300;
        }
    }
}

.campaign-title {
    display: flex;
    gap: 5px;
    align-items: center;
}