.blockedSessions-graphs-section {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: calc(30.33vh - 40px) calc(30.33vh - 40px) calc(40.33vh - 40px) calc(40.33vh - 40px);
    gap: 20px;
    width: 100%;
    height: 100%;
    position: relative;
}
.zoom-tooltip {
    margin-top: auto;
    position: absolute;
    width: 99%;
    text-align: right;
    color: #c2c2c2;
    font-size: smaller;
    font-weight: 200;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.4s;
    margin-left: auto;
}
.hideToolTip {
    opacity: 0;
    display: none;
}

.zoom-controls-parent {
    width: 98.5%;
    position: absolute;
    display: flex;
    align-items: right;
    flex-direction: column;
    margin-left: auto;
    z-index: 2;
    margin-top: -48px;
}

.zoom-controls {
    text-align: center;
    font-family: Playfair Display;
    font-size: larger;
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-top: auto;
    background-color: #f4f4f4;
    border: solid 1px #c2c2c2;
    color: #4b4b4b;
    border-radius: 5px;
    margin-top: 5px;
}

.zoom-controls:hover {
    filter: brightness(95%);
}

.zoom-controls:active {
    filter: brightness(92%);
}

.zoom-controls.zoom-in-btn {
    background-image: url(../../../images/zoom-in.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.zoom-controls.zoom-out-btn {
    background-image: url(../../../images/zoom-out.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.blockedSessions-Traffic-sources-section {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: calc(55.33vh - 40px);
    gap: 20px;
    width: 100%;
    height: 100%;
}

.BlockedSessions-GeographyGraph {
    grid-column: 1/6;
    background-color: #fff;
    border-radius: 8px;
    grid-row: 1/3;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.countries-table-tooltip {
    margin: 7px 7px 0 0;
}

.countries-table-list {
    grid-column: 6/-1;
    background-color: #fff;
    border-radius: 8px;
    grid-row: 1/3;
    position: relative;
    font-weight: 500;
    overflow: hidden;
    overflow-y: scroll;
}

.countries-table {
    padding: 0px 7px 15px 0;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    border-radius: 8px;
    margin-left:10px;
    overflow-x: hidden;
}

.countries-list th {
    position: sticky;
    top: 0;
    background: white;
    margin-top: -16px;
}

.countries-list::-webkit-scrollbar {
    width: 0.2em;
    height: 8px;
}

.data-table > thead > tr {
    height: 5px;
}

.countries-list {
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 100%;
    // padding: 0 0 15px 15px;
    width: 100%;
}

.countries-table::-webkit-scrollbar{
    width:0.2em
}
.countries-table::-webkit-scrollbar-thumb{
    width:0.2em
}

.data-table {
    width: 90%;
}


.BlockedSessions-WaffleGraph {
    grid-column: 1/5;
    background-color: #fff;
    grid-row: 3/3;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.BlockedSessions-HeatMap {
    grid-column: 5/-1;
    background-color: #fff;
    grid-row: 3/3;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.BlockedSessions-PieChart {
    grid-column: 1/7;
    background-color: #fff;
    grid-row: 1/1;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.toggle-active-geoGraph {
    height: calc(50.5vh);
    width: calc(50.60vw);
    transition: all 0.2s;
}

.toggle-deactive-geoGraph {
    height: calc(50.33vh);
    width: calc(55.60vw);
    transition: ease all 0.2s;
}

.loading-bots-activity-map {
    filter: blur(1px);
    width: 100%;
    height: 100%;
    position: relative;
}

.loading-piechart {
    grid-column: 7/-1;
    background-color: #fff;
    grid-row: 1/1;
    border-radius: 8px;
    padding: 7px 7px 0px 0;
    font-size: 18px;
    font-weight: 500;
    filter: blur(1px);
}

.graphs-loading-img {
    position: absolute;
    width: 100px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bots-activity-map {
    width: 100%;
    height: 95%;
}
