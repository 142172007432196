.permission-message {
	position: fixed;
	bottom: 7vh;
	/* left: 0; */
	/* width: 100%; */
	border-radius: 8px;
	padding: 5px 10px;
	text-align: center;
	background-color: purple;
	left: 50%;
	color: white;
	transform: translateX(-50%);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	transition: opacity 0.6s ease-in-out; 
}