body {
	font-family: 'Montserrat';
}

.topbar {
	background: #FFFFFF;
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: 3;
	height: 3.2rem;
	top: 0px;
}
.clock {
	display: flex;
	/* width: 30%; */
	justify-content: space-between;
	margin-left: 1%;
	transition: all 0.5s ease;
}

.user-account {
	width: 350px;
	margin-right: -15px;
	display: flex;
	color: #1F1F1F;
	justify-content: space-evenly;
	align-items: center;
	display: inline-grid;
	grid-template-columns: 100px 90px 60px 100px;
}

.website-name {
	font-weight: 700;
	font-size: 16px;
	margin-right: 20px;
	color: #1F1F1F;
}

.time {
	margin-left: 20px;
	margin-right: 8px;
	font-weight: 400;
	font-size: 16px;
	color: #1F1F1F;
	width: 74px
}

.date {
	margin-right: 60px;
	font-weight: 400;
	font-size: 16px;
	color: #1F1F1F;
}

.links {
	text-decoration: none;
	color: #1F1F1F;
}

.switch-account,
.my-account {
	margin-left: 8px
}

.icon-box {
	/* min-width: 65px;  */
	cursor: pointer;
}

.icon-box-active {
	font-weight: bold;
	cursor: pointer;

}

.icon-box:hover {
	font-weight: bold;
}

.switch-toggle-data {
	padding: 6px 6px;
}

.switch-toggle-data:hover {
	background-color: #D8D8D8;
	cursor: pointer;
	border-radius: 8px;
}

.switch-website {
	/* border-top:1px solid #D8D8D8; */
	padding: 6px 6px;
	margin-top: 7px;
}

.switch-website:hover {
	background-color: #D8D8D8;
	cursor: pointer;
	border-radius: 8px;
}

.triangle,
.me-toggle-triangle, .help-toggle {
	position: absolute;
	top: 3.2rem;
	background-color: #fff;
	padding: 10px 20px 5px 8px;
	border-radius: 8px;
	border: 1px solid #1F1F1F;
	z-index: 2;
}

.help-toggle{
	margin-left:135px
}

.triangle {
	margin-left: 52px;
}

.me-toggle-triangle {
	right: -30px;
	margin-right: 45px;
}


.triangle::after, .help-toggle:after {
	border-top: 0px solid black;
	border-left: 1px solid black;
	border-right: 0px solid black;
	border-bottom: 1px solid black;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0px;
	right: 78px;
	transform: translateY(-60%) rotate(135deg);
	content: "";
	background-color: #fff;
}

.me-toggle-triangle::after {
	border-top: 0px solid black;
	border-left: 1px solid black;
	border-right: 0px solid black;
	border-bottom: 1px solid black;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0px;
	left: 73px;
	/* right: 22px; */
	transform: translateY(-60%) rotate(135deg);
	content: "";
	background-color: #fff;
}