.sidebar-content p {
    font-size: 16px;
}

.links {
    text-decoration: none;
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.links:hover {
    font-weight: bolder;
}