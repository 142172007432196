.modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    text-align: center;

    .modal-header {
        display: flex;
        padding: 2px 1em;
        justify-content: space-between;

        .modal-heading {
            color: #662D91;
            display: flex;
        }

        .close-mark {
            margin-bottom: 0px;
            cursor: pointer;
            font-size: 1.2vw;
            padding: 3px 6px;
            border-radius: 3px;


            &:hover {
                cursor: pointer;
                background-color: lightgray;
                padding: 3px 6px;
                background-color: rgb(231, 226, 226);
                border-radius: 2%;
                height: max-content;
            }
        }
    }

    .dropdown-header {
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;
        background-color: #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0px auto;
        text-transform: capitalize;
    }

    .dropdown-header span {
        margin-left: 8px;
    }

    .dropdown-options {
        list-style-type: none;
        margin: 0;
        text-align: left;
        margin: 0px auto;
        border-top: none;
        border-radius: 0 0 4px 4px;
        overflow-y: scroll;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 25%;
        padding-left: 0;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        max-height: 100px;
    }

    .dropdown-options li {
        border-bottom: 1px solid #dddbdb;
        text-transform: capitalize;
    }

    .dropdown-container:hover .dropdown-options {
        display: block;
    }

    .dropdown-options li {
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .dropdown-options li:hover {
        background-color: #f0f0f0;
    }

    .adNetworks {
        width: 40%;
        height: 2em;
    }

    .confirm-button,.close-button {
        padding: 12px 24px;
        margin-left: 10px;
        border-radius: 8px;
        font-family: "Montserrat";
        background-color: #662D91;
        color: #fff;
        border: 1px solid #662D91;
        cursor: pointer;
        margin-right: 6px;
        position: relative;
        // padding: 10px 16px;
        margin: 20px auto;
        font-size: 2vh;
        font-weight: 600;

        &.remove {
            background-color: #E2293780;
            border: none;
        }
    }

    .dropdown-container {
        margin-bottom: 20px;
        margin-right: auto;
        margin-left: auto;
        width: 70%;
    }

}


.adNetwork_root {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    padding: 10px 20px 15px 20px;
}

.main {
    background-color: #fff;
    padding: 10px 20px 15px 20px;
    border-radius: 8px;
    grid-column: 1/-1;
    // margin-bottom: 50px;
    //width: 90%;
    position: relative;

    &.loader {
        background-color: #fdfdfd;
    }

    .flex-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .connections-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .loader-animation {
        font-size: 4px;
        margin: 0 auto;
        margin-bottom: 5%;

    }

    .table {
        display: table;
        width: 100%;
        border-spacing: 0 .5em;

        .flex {
            display: table-row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;

            .icon {
                color: rgb(26, 24, 24);
            }

            &.loader {
                .button {
                    display: none;
                }

                .text {
                    opacity: 0.5;
                    content: "";
                    background-color: #b7b7b7;
                    color: #b7b7b7;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-wrap: nowrap;
                }
            }


            .text {
                display: table-cell;
                box-sizing: border-box;
                border: none;
                padding-left: 14px;
                text-transform: capitalize;
                font-family: "Montserrat";
                border-bottom: 1px solid #d9d9d9;

                &.error {
                    color: red;
                    text-transform: none;
                }
            }

            .button {
                font-size: 1em;
            }
        }
    }

    .button {
        padding: 8px 16px;
        margin-left: 10px;
        border-radius: 8px;
        font-family: "Montserrat";
        background-color: #662D91;
        color: #fff;
        border: 1px solid #662D91;
        cursor: pointer;
        margin-right: 6px;
        position: relative;
        padding: 10px 16px;
        width: max-content;

        &.remove {
            background-color: #E2293780;
            border: none;
        }
    }
}

.okay-btn {
    margin: auto 10px 20px 10px;
    border-radius: 8px;
    padding: 12px 16px;
    border: none;
    font-size: 16px;
    color: white;
    font-family: "Montserrat";
    background-color: #49B65B;
    border: 1px solid #49B65B;
    cursor: pointer;
}

.okay-btn:hover {
    background-color: #fff;
    color: #49B65B;
    transition: all 0.5s ease;
}

.selected-option {
    color: #49B65B;
    width: 70%;
    margin: 20px auto;
    text-transform: capitalize;
}