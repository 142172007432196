.email-notification-content {
    background-color: #fff;
    padding: 10px 20px 15px 20px;
    border-radius: 8px;
    /* margin-top: 67px; */
    grid-row: 3/4;
    grid-column: 1/-1;
    visibility: hidden; /*delete this to make it appear again*/
}

.email-content {
    display: "flex";
    gap: 20;
    align-items: "center";
    margin-bottom: -10;
    background-color: red;
}
.email-toggles{
    display:flex ;
    gap:20px;
    margin-bottom:10px;
    align-items: center;
}
.toggle{
    /* transform: translateY(-50%) rotate(180deg); */
    transition: all 0.5s ease;
    cursor: pointer;
}
.email-toggle-button {
    display: inline-block;
    position: relative;
    width: 55px;
    height: 27px;
}

.email-toggle-button input[type="checkbox"] {
    display: none;
}

.email-toggle-button .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d63030;
    border-radius: 17px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.email-toggle-button .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 22px;
    left: 3px;
    bottom: 3.5px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.email-toggle-button.active .slider {
    background-color: #662D91;
}

.email-toggle-button.active .slider:before {
    transform: translateX(23px);
}

.email-toggle-button.inactive .slider {
    background-color: #ccc;
}

.email-toggle-button label {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.email-toggle-button input[type="checkbox"]:checked {
    transform: translateX(26px);
}