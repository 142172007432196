.help {
	font-weight: 800;
	font-size: 25px;
	margin-top: 5px;
}

.help-section {
	grid-column: 1/-1;
	overflow: auto;
	padding: 10px 0px;
	margin-bottom: 60px;
}

.filter-help {
	margin: 18px 0px;
}

.help-buttons {
	border-radius: 50px;
	border: 1px solid #662D91;
	color: #662D91;
	padding: 8px 16px;
	height: 36px;
	gap: 10px;
	cursor: pointer;
	margin-left: 8px;
	font-family: 'Montserrat';
}

.help-buttons:hover {
	background-color: #662D91;
	color: #fff;
	cursor: pointer;
	transition: all 0.5s ease;
}

.support-section {
	display: grid;
	gap: 19px;
	grid-template-columns: repeat(3, 1fr);
	margin-top:15px
}

.question-text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: bolder;
	font-size: 25px;
	color: #000000;
}
.contact-support-button{
	background-color: #662D91;
	padding:12px 16px;
	color:#fff;
	border:1px solid #662D91;
	font-family: "Montserrat";
	border-radius: 8px;
	font-size: 16px;
	cursor: pointer;
}
.contact-support-button:hover{
	background-color: #fff;
	transition: all 0.5s ease;
	color: #662D91;
}