.dynamicLinks-container{
    margin-top: 26px;
    background: white;
    padding: 1px 16px;
    border-radius: 4px;
    width:95%;
    margin-bottom: 10%
}


.cards{
    border: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.cards div{
    flex: 1 1 auto;
    padding: 10px;
}
.cards div:nth-of-type(1){
    border-bottom:  1px solid #eaeaea;
}

hr{
    border-top: 0.5px solid #ffffff;
}

.ads-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    font-size: 1em;
}
