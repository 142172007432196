.remove-user-confirm-button{
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 16px;
    background-color: #fff;
    color: red;
    border: 1px solid red;
	font-family: "Montserrat";
	cursor: pointer;
	margin-top:15px;
	align-self: center;
}

.remove-user-confirm-button:hover{
    background-color: red;
    color: #fff;
    transition: all 0.5s ease;
}

.remove-user-close-button{
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
	margin-top:15px;
	align-self: center;
}

.remove-user-close-button:hover{
	background-color: #fff;
	color: #662D91;
	transition: all 0.5s ease;
}

.remove-user-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.remove-user-modal-content {
	/* height: 45vh; */
	background-color: white;
	border-radius: 20px;
	padding: 10px 20px;
	overflow-y: auto;
	min-width:  30vw;
	display: flex;
	flex-direction: column;
}
.remove-user-modal-status-text{
	text-align: center;
	text-justify: center;
}
.remove-close-modal {
    width: 1.5vw;
    height: 1.5vw;
    align-self: end;
    text-align: center;
    justify-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 50%;
	cursor: pointer;
}

.remove-close-modal h3 {
	font-family: "Montserrat";
}

.remove-close-modal:hover {	
	background-color: rgb(231, 226, 226);	
}
