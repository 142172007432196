.bot-risk-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: calc(52vh - 20px) ;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.bot-intentions-container,
.bot-type-container,
.risk-measurement-container {

    background-color: #fff;
    border-radius: 8px;
    padding: 7px
}

.risk-measurement-container {
    grid-column: 1/7;

    .risk-status {
        margin-left: 19px;
        margin-top: 15px;
        font-size: 1.5vh;

        span:nth-child(1) {
            color: white;
            padding: 3px 12px;
            border-radius: 50px;
            margin-right: 10px;
        }
    }

}

.bot-threat-container {
    grid-column: 1/7;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 0.5rem;
    .bot-intentions-classification{
        overflow-y: scroll;
        height: 65%;
      }
}

.bot-type-container {
    position: relative;
    grid-column: 5/9;
}

.bot-intentions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 3px 10px;
    position: relative;
}

.bot-intentions-info {
    border: 1px solid #dfdcdc;
    border-radius: 8px;
    padding: 3px 7px;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    line-height: 15px;
    position: relative;

    .risk-title {
        font-weight: bold;
        margin-left: 25px;
        margin-bottom: 6px;
        margin-top: 6px;
        
    }

    .capsules{
        width: 16px;
        height: 90%;
        position: absolute;
        top: 0px;
        border-radius: 4px;
        transform: translateY(5%);
    }

    .bot-type-classification {
        margin-left: 25px;
        div:nth-child(1){
            font-weight:bold ;
        }
    }
   
}

.bar-legends {
    display: inline-flex;
    gap:2px;
    align-items: center;
    font-size: 0.6vw;
}
.legends-value{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;

}
.legend-key{
    display: flex;
    align-items: center;
    gap:6px
}
.legends-section{
    margin: 8px 10px;
    display: flex;
    gap: 14px;

}
.header-section{
    display: flex;
    justify-content: space-between;
}
.bot_risk_cell {
    border-radius: 50px;
    color: #fff;
    font-size: .7vw;
    margin-left: auto;
    margin-right: 7px;
    padding: 3px 12px;
    text-align: right;
    width: max-content;
}