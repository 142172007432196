.human-graph-area {
	display: grid;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: calc(50.33vh - 40px) calc(50.33vh - 40px) ;
    gap:20px;
	width: 100%;
    position: relative;
}
.countries-lineGraph-BrowserGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 1/5;
}
.referral-OSlineGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 5/-1;

}
.countries-lineGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 1/7;
    grid-row: 1/2;
}
.referral-lineGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 7/-1;
    grid-row: 1/2;
}
.finishedSessions-graph-area {
	display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-template-rows: calc(30.33vh - 40px) calc(30.33vh - 40px);
    gap:20px;
	width: 100%;
	height: 100%;
}
.human-BrowserLineGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 1/7;
    grid-row:2/-1;
}

.human-OSlineGraph{
    background-color: #fff;
    border-radius: 8px;
    padding: 7px 7px 0px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    grid-column: 7/-1;
    grid-row:2/-1;
}
.finishedSessions-pie{
    grid-column: 1 / 4;
    background-color: #fff;
    border-radius: 8px;
    grid-row: 1 / 3;
    position: relative;
}

.finished-sessions-legend-section {
	position: absolute;
	top: 6rem;
	right: 2.5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
}