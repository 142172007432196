.bots-percentage-container {
	grid-column: 1/3;
	background-color: #fff;
	border-radius: 8px;
	background: url("../../../../../../components/images/bots-image.svg") white right;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	position: relative;

}


.bots-percentage-content {
	padding: 0px 29px
}

.bots-percentage-value {
	color: rgba(226, 41, 55, 1);
	font-weight: bolder;
	font-size: 3.3vw;
	margin-top: 10px
}

.bots-values {
	font-size: 1vw;
	margin-top: 10px;
	margin-bottom: 10px;
}

.bots-growth-rate {
	font-size: 1.5vw;
	color: rgba(226, 41, 55, 1);
	margin-top: 10px
}

.bots-difference {
	font-size: 1vw;
}