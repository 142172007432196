
.login-head {
    /* width: 252px;
    margin: auto;
    margin-bottom: 2%; */
}

// .container {
// 	margin-left: -1rem;
// }

.signup-content {
	display: flex;
	// align-items: center;
	margin: 2% 2% 0% 2%;
	justify-content: end;
}

.account-text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: #FFFFFF;
	margin-right: 28px;
	margin-top: 10px;
}

.signup-button {
	padding: 0 30px;
	height: 40px;
	font-family: 'Montserrat';
	color: #662D91;
	font-weight: 700;
	font-size: 16px;
	border-radius: 8px;
	border: 1px solid #FFFFFF;
	cursor: pointer;
}

.signup-button:hover {
	color: #FFFFFF;
	background-color: #662D91;
}

.veracity-logo{
    display: flex;
	margin-right: auto;
}
.veracity-logo img{
    width: 250px;
	margin-left: -1rem;
}