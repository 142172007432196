.website-heading {
    font-weight: 800;
    font-size: 25px;
    margin-top:5px
}

.website-content {
    grid-column: 1/-1;
    overflow: auto;
    padding: 10px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
    margin-bottom: 1%;
}