.date-drop-down {
	/* width: 170px; */
	min-width: 220px;
	height: auto;
	display: flex;
	flex-direction: column;	
	border-radius: 8px;
	background: #FFFFFF;
	cursor: pointer;
	position: relative;
	transition: all, 1.5s, ease;
	border: 1px solid #FFFFFF;
}
.date-drop-down2 {
	/* width: 170px; */
	z-index: 2;
	min-width: 220px;
	height: auto;
	display: flex;
	flex-direction: column;	
	border-radius: 8px;
	background: #FFFFFF;
	cursor: pointer;
	border: 1px solid black;
	position: relative;
	transition: all, 1.5s, ease;
}
.date-drop-down:hover{
	border: 1px solid black;
}
.date-drop-down-option, .date-drop-down-option-selected {
	display: flex;
    align-items: center;
    justify-content: space-between;
	text-wrap: nowrap;
	padding: 3% 5%;
}
.date-drop-down-option-selected span, .date-drop-down-option span{
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 0.8vw;
	line-height: 150%;
}
.date-drop-down-option:hover {
	background-color: #F4F4F4;
}
.date-drop-down-option:last-child {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.date-dropdown-container {
	position: relative;
	min-width: 205px;
	height: 30px;
}