.app-maintenance-container {
    text-align: center;
    margin-top: 3vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
        width: 250px;
    }

    div {
        border-radius: 6px;
        background-color: #ffffff;
        margin-top: 3vh;
        width: 50vw;

        h2 {
            font-weight: 800;
            color: #662D91;
        }

        h3 {
            width: 66%;
            margin: 0px auto;
        }
    }
}