.mfa-content {
	width: 505.76px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5%;
	padding-bottom: 1.5%;
	background: #FFFFFF;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}

.header {
	text-align: center;
	color: #662D91;
	font-family: 'Montserrat';
	font-weight: bolder;
	font-size: 25px;
	padding-top: 43px
}

.selection-buttons {
	display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
}

.cancel-mfa {
	border-radius: 8px;
	padding: 8px 32px;
	font-size: 16px;
	outline: none;
	border: 1px solid #1F1F1F;
	font-family: "Montserrat";
	background-color: #FFFFFF;
	cursor: pointer;
	width: 181px;
	height: 48px;
}

.verify-mfa {
	border-radius: 8px;
	padding: 8px 32px;
	font-size: 16px;
	outline: none;
	border: 1px solid #49B65B;
	font-family: "Montserrat";
	background: #49B65B;
	color: #FFFFFF;
	cursor: pointer;
	width: 181px;
	height: 48px;
}

.cancel-mfa:hover {
	background-color: red;
	color: #FFFFFF;
	outline: none;
	border-color: red;
}

.details-section {
	color: #662D91;
	display: flex;
	flex-direction: column;
	gap:10px;
	justify-content: center;
}
.mfa-input{
	background-color: #F4F4F4;
    color: black;
    outline: none;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    line-height: 43px;
    width: 75%;
    margin-top: 18px;
    padding: 2px 8px;
}