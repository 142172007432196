.table-container {
    display: table;
    width: 100%;
    color: white;
    table-layout: fixed;
}

.table-rows,
.table-data-rows {
    display: table-row;
    text-align: left;
   
}

.table-rows{
    position: sticky;
    top: 0;
    text-transform: capitalize;
}

.table-data-rows:nth-child(even) {
    background-color: rgba(218, 218, 218, 0.78);
}
.table-data-rows:hover {
    background-color:#D9C6FF;
}

.table-head {
    display: table-cell;
    padding: 0.8rem;
    background-color: #662D91;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-head:nth-child(1) {
    border-top-left-radius: 0.5rem;
}

.table-head:nth-last-child(1) {
    border-top-right-radius: 0.5rem;
}

.table-cell {
    display: table-cell;
    color: black;
    padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
link:hover{
    background-color: red;
}
.table-footer {
    display: table-footer-group;
}
