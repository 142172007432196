.graph-tile {
    padding: 1%;
	// padding-bottom: 80px;
}
.graph-tile-header{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.graph-body {
    width: 100%;   
	box-sizing: border-box;
	overflow: visible;
}
.error{
	color:red;
	font-weight: bold;
}
.support-link{
	margin-top: 20px;
    padding: 8px 16px;
    color: #fff;
    background: #49B65B;
    border: 1px solid #49B65B;
    border-radius: 8px;
    font-size: 16px;
	cursor: pointer;
	font-family: "Montserrat"
}
.support-link:hover{
	background-color: #fff;
    color: #49B65B;
    transition: all 0.5s ease;
}