.affiliates-linegraph-container {
    height: 100%;
}

.linegraph-container {
    padding: 0;
    font-size: 18px;
    font-weight: 500;
	position: relative;
    height: 100%;
}

.affiliates-grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: calc(13.5vh - 20px) calc(13.5vh - 20px) calc(13.5vh - 20px) calc(13.5vh - 20px) calc(13.5vh - 20px) calc(13.5vh - 20px);
    height: 100%;
    width: 100%;

    .graph {
        background-color: #fff;
        box-shadow: 2px 2px 10px 0px #e3e2e2;
        // margin: 1em 0 0;
        border-radius: 10px;
        position: relative;
    }

    .item-1 {
        grid-row: 1/ span 6;
        grid-column: 1/6;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 2px 2px 10px 0px #e3e2e2;

        .active-campaign-panel {
            height: 100%;
            box-shadow: none;
        }

        .graph-header {
            margin-bottom: -30px;
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
        }
        
        .tooltip {
            margin: 4px 4px 0 0;
        }
    }

    .table {
        width: 94.5%;
    }

    .item-2 {
        grid-row: 1/ span 3;
        grid-column: 6/9;  
        padding: 3px;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 2px 2px 10px 0px #e3e2e2;
    }

    .item-3 {
        grid-row: 4/ span 3;
        grid-column: 6/9;
        padding: 3px;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 2px 2px 10px 0px #e3e2e2;
    }

    .item-4 {
        grid-row: 1/ span 2;
        grid-column: 6/9;
    }

    .tooltip {
        margin: 7px 7px 0 0;
    }

    .max-icon {
        margin: 7px 7px 0 0;
    }
}

.affiliate-table-container-parent {
    padding: 5px;
    background-color: white;
    border-radius: 8px;
    
    .affiliates-graph-header {
        margin-bottom: -48px;
        width: 99.8%;
        display: flex;
        flex-direction: row-reverse;
    }
    
    .tooltip {
        margin: 4px 4px 0 0;
    }
}

.affiliate-table-container {
    // border-radius: 8px;
    height: 79vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1px;
    // border: 1px solid white;
    // box-sizing: border-box;

    .cell.affiliates {
        border: 1px solid #F4F4F4;
        text-align: left;
    }

    .cell.affiliates.affiliate-name {
        font-weight: 700;
    }

    .table {
        height: auto;
        width: 96.8%;
        background-color: white;
    }

    // .tbody > div:last-child .cell.affiliates {
    //     border-radius: 0 0 8px 8px;
    // }
}

.affiliate-table-container::-webkit-scrollbar {
    width: 0.2em;
    background-color: transparent;
}

.lheader.table-row.affiliates  div{
    background-color: white;
    position: sticky;
    top: 0;
    // padding: 1em;
    // margin-top: -3px;
}