.sidebar-main {
    height: 100vh;
   

    nav {
        flex-grow: 6;
        margin-top: 1rem;
        padding: 0px,
    }

    .openArrow {
        position: absolute;
        right: 0;
        transform: translateY(-50%) rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .closeArrow {
        position: absolute;
        right: 0;
        display: flex;
        transition: all 0.3s ease;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;
    }

    .protection-section {
        position: absolute;
        bottom: 53px;
        left: -9px;
        width: 100%;
        color: #f5f5f5;
        /* padding: 10px; */
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        border-top: "1px solid white"
    }

    .protection-status {
        text-align: center;
        color: white;
        padding-top: 10%;
        position: relative;
        bottom: 5%;
    }

    .status-text {
        font-weight: bolder;
        font-size: 100%;
        color: #f5f5f5;
    }

}

.bottom-logo {
    text-align: center;
    color: white;
    padding-top: 1rem;
    // flex-grow: 1.5;
    margin-bottom: 50px;
}
.currency_symbol {
    position: absolute;
    z-index: 5;
    color: white;
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 1px;
}