.StatusTabs{
	width: 100%;
	height: 40px;
	// margin: 13px 0;
	background: rgba(255, 255, 255, 0.08);
	display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    color: white;
	overflow: hidden;
	justify-content: space-between;
	text-overflow: ellipsis;
	box-sizing: border-box;
	flex-wrap: nowrap;
	&--Collapsed{
		transition: all;
		justify-content: center;
		&>div, .StatusTabs-toolTipSVG{
			display: none;
		}
	}
}
.StatusTabs-textBox{
	display: flex;
    flex-direction: column;
	margin: 10px 10px 10px 0px;
	cursor: default;
	overflow: hidden;
	flex-wrap: nowrap;
	text-overflow: clip;
	width: 100%;
}
.StatusTabs-title{
	line-height: 12px;
	padding: 0;
    margin: 0;
}
.StatusTabs-span{
	font-size: 10px;
	font-weight: 300;
	text-align: left;
	line-height: 12px;
	
}
.StatusTabs-title, .StatusTabs-span{
	text-overflow: clip;
	white-space: nowrap;
}

.StatusTabs-SVG{
	margin: 10px;
	min-width: 16px;
}
.StatusBox{
	display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-bottom: 20px;
	transition-property:all;
}

.StatusTabs-span b{
	font-weight: 500;
}

.StatusTabs-toolTipSVG{
	margin: 10px;
	min-width: 20px;
	box-sizing: border-box;
	rect{
		transition: opacity 500ms;
	}
	
	&:hover{
		cursor: pointer;		
		rect{
			opacity: 20%;
		}
		
	}
}
.StatusTabs-SVG-Slice{
	opacity: 30%;
}

@mixin animation-mixin($name) {
	@keyframes #{$name} {
		// 0% {opacity: 30%;}
		25% {opacity: 30%;}
		50% {opacity: 100%;}
		75% {opacity: 30%;}
		// 100% {opacity: 30%;}
	}
  }

@include animation-mixin(S1);
@include animation-mixin(S2);
@include animation-mixin(S3);
@include animation-mixin(S4);

#Slice-1{
	animation: S1 4s 0s ease infinite;
}
#Slice-2{
	animation: S2 4s 1s ease infinite;
}
#Slice-3{
	animation: S3 4s 2s ease infinite;
}
#Slice-4{
	animation: S4 4s 3s ease infinite;
}