.new-user {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.modal-content {
	/* height: 45vh; */
	background-color: white;
	border-radius: 20px;
	padding: 10px 20px;
	overflow-y: auto;
	min-width:  30vw;
}

.modal-name {
	font-size: 1.1vw;
}

.modal-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.permissions-header{
	margin-bottom:4px
}
.close-modal {
	font-size: 1.2vw;
	font-weight: 500;
	padding: 4px 10.5px;
	border-radius: 4px;
	background-color: #F4F4F4;
}

.close-modal:hover {
	cursor: pointer;
	background-color: #f1f1f1;
}

.website-checklist {
	margin-top: 1.5vh;
	display: flex;
	gap: 10px;
}


.checklist-section {
	display: flex;
	flex-direction: column;

}

.invite {
	box-sizing: border-box;
	background: #F4F4F4;
	border-radius: 12px;
	line-height: 40px;
	border: none;
	padding-left: 14px;
	font-family: "Montserrat";
	/* width: 432px; */
	width: 100%;
	margin-top: 6px;
}
.invite-button-disable{
	opacity: 0.6;
	padding: 8px 16px;
	border-radius: 8px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: not-allowed;
}

body.active-modal {
	overflow-y: hidden;
	background-color: gray;
}

.invite-button {
	padding: 8px 16px;
	border-radius: 8px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
}

.confirm-button {
	padding: 11px 16px;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
	float: right;
	margin-top:15px;
}

.datalist-input {
	box-sizing: border-box;
	background: #F4F4F4;
	border-radius: 12px;
	height: 40px;
	border: none;
	padding-left: 14px;
	font-family: "Montserrat";
	width: 200px;
}

.new-user-content {
	justify-content: space-between;
	display: flex;
	gap: 22px;
	flex-direction: column;
	padding-bottom: 20px;
}

.invite-button:hover {
	background-color: #fff;
	color: #662D91;
	transition: all 0.5s ease
}

.confirm-button--cant {
	cursor: not-allowed;
}