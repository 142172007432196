.goodBots-graphs-section{
	display: grid;
    grid-template-columns: repeat(12,1fr);
	grid-template-rows: calc(40.33vh - 20px) calc(35.33vh - 20px) ;
    gap:20px;
	width: 100%;
	height: 100%;
}
.goodBots-description{
	grid-column: 6/-1;
	background-color: #fff;
	border-radius: 8px;
	padding: 1px 10px;
	padding: 7px 7px 15px 10;
    font-size: 18px;
    font-weight: 500;
	grid-row: 1/-1;
}
.google-bots,.apple-bots,.facebook-bots,.bytespider-bots,.other-bots{
	grid-column: 1/7;
	background-color: #fff;
	border-radius: 8px;
	padding: 7px 7px 15px 10px;
    font-size: 18px;
    font-weight: 500;
	grid-row: 1/2;
	position: relative;
}
.apple-bots{
	grid-column: 7/-1;
	grid-row: 1/2;
}
.facebook-bots{
	grid-column: 1/5;
	grid-row: 2/-1;
}
.bytespider-bots{
	grid-column: 5/9;
	grid-row:2/-1;
}
.other-bots{
	grid-column: 9/-1;
	grid-row: 2/-1;
}
.selection-box{
	padding: 6px 10px;
    border-radius: 8px;
    width: 122px;
    font-size: 16px;
    font-family: "Montserrat";
}