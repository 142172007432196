.delete-account-section {
	/* grid-row: 3/5; */
	background-color: #fff;
	padding: 10px 20px 15px 20px;
	border-radius: 8px;
	grid-column: 2/2;
	grid-row: 2/3;
}

.delete-account-button {
	color: red;
	float: right;
	border: 1px solid red;
	padding: 13px 16px;
	background-color: #fff;
	border-radius: 8px;
	cursor: pointer;
	font-family: "Montserrat";
	font-size: 16px;
	margin-top: 14px;
	width: 186px;
}

.delete-account-disable {
	opacity: 0.6;
	cursor: not-allowed;
	color: red;
	float: right;
	border: 1px solid red;
	padding: 13px 16px;
	background-color: #fff;
	border-radius: 8px;
	font-family: "Montserrat";
	font-size: 16px;
	margin-top: 14px;
	width: 186px;
}

.delete-account-button:hover {
	background-color: red;
	color: #fff;
	transition: all 0.5s ease
}

.delete-input {
	box-sizing: border-box;
	background: #F4F4F4;
	border-radius: 12px;
	height: 40px;
	border: none;
	font-family: "Montserrat";
	width: 432px;
	margin: 7px 0px;
	align-items: center;
	padding: 0 12px;
}

.delete-section {
	display: flex;
	flex-direction: column;
	width: max-content;
}