body {
    margin: 0;
    padding: 0;
    font-family: "montserrat", "Arial", "sans-serif";
}

.app {
    display: flex;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    font-family: "montserrat", "Arial", "sans-serif";


    .main {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 1rem;
        font-family: "montserrat", "Arial", "sans-serif";
    }

    body {
        overflow-x: hidden;
    }

    .graph-heading {
        margin-left: 18px;
        font-weight: bold;
        margin-top: 10px;
        font-size: 1.1vw;
    }

    .graph-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        height: 30px
    }

    .traffic-message {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%
    }

}

::-webkit-scrollbar {
    width: 0px
}

::-webkit-scrollbar-track {
    width: 0
}

::-webkit-scrollbar-thumb {
    width: 0em;
    background-color: #e0dbdb;
    cursor: pointer;
    border-radius: 22px;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.2em;
    cursor: pointer;
    border-radius: 22px;
}

/* hide the browser's built in password hider because it would conflict with ours, the browser's one would be preferable but they're not yet universal or mandated */
::-ms-reveal {
    display: none;
}

::-moz-reveal {
    display: none;
}

::-webkit-reveal {
    display: none;
}

::reveal {
    display: none;
}

.content-div{
    background-color: #fff;
    border-radius: 8px;
}

.warning-bar{
    border: solid 1px red;
    background: pink;
    border-radius: 10px;
    padding: 15px;
    margin: 35px 10px;
    width: 95%;
    // box-sizing: border-box;
}