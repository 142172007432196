.good-bots-content {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: calc(55.33vh - 40px);
    // overflow: hidden;
}

.bot-blocked-count-text {
    color: #6EBA7A;
    font-family: Montserrat;
    font-size: 61px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    /* 73.2px */
    margin: 0px 0px;
}

.bot-blocked-count-sub-text {
    margin-top: 0px;
}

.protection-header {
    display: flex;
    justify-content: space-between;

    .tooltip {
        margin: 7px 7px 0 0;
    }
}

.bold-protection-text {
    font-weight: bolder;
    margin-bottom: 5px;
}

.protection-text-purple {
    color: #662D91;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-text-one {
    grid-column: 1 / span 4;
    grid-row: 1 / -1;
    border-radius: 8px;
    background: white;
    background-repeat: no-repeat;
    position: relative;

    .loader {
        margin: 33% auto;
    }

    .goodbots-legend-section {
        position: absolute;
        top: 15vh;
        right: 1.5vw;
        display: flex;
        gap: 0.5vw;
        flex-direction: column;
    }
}

.header-text-two {
    grid-column: 5 / span 4;
    grid-row: 1 / -1;
    border-radius: 8px;
    background: white;
    padding-right: 20px;
    margin-bottom: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .CO2-section {
        display: flex;
        position: absolute;
        top: 2.5rem;
        width: 92%;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;
        padding-left: 20px;

        .emmision-section {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .emmision-value {
                font-weight: bolder;
                font-size: 3vw;
                color: #662D91;
            }

            .emmision-text {
                font-weight: bold;
                font-size: 1vw;
            }
        }

        .equivalent-section {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .equivalent-heading {
                font-weight: bold;
                font-size: 1vw;
            }

            .equivalent-value {
                font-weight: bolder;
                font-size: 1.6vw;
                color: #662D91;
            }

            .equivalent-description {
                font-size: 1vw;
            }
        }

        .smartphone-section {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .smartphone-cycle-value {
                font-weight: bolder;
                font-size: 1.6vw;
                color: #662D91;
            }

            .smartphone-cycle-description {
                font-size: 1vw;
            }
        }
    }

    .tooltip {
        margin: 7px -13px 0 0;
    }

    .loader {
        margin: 50% auto;
    }
}

.CO2-icon {
    position: absolute;
    width: 4vw;
    margin-left: 7px;
}

.C02-saving-text-title {
    text-align: left;
    margin-top: 0px;
    color: var(--Black, #1F1F1F);
    font-weight: 800;
    line-height: 120%;
}

.C02-saving-text-amount-text {
    text-align: left;
    margin: 0px;
    color: var(--Black, #1F1F1F);
    font-size: 61px;
    font-weight: 800;
}

.CO2-desc {
    font-weight: 400;
    margin-bottom: 0;
    height: 40px;
}

.C02-saving-overtime-text {
    color: var(--Black, #1F1F1F);
    text-align: left;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top: 15px;
}

.CO2-source-text {
    font-size: 9px;
    text-align: left;
    position: absolute;
    bottom: 0;
}

.CO2-source-text-green {
    color: #6EBA7A;
}

.CO2-savings-graph {
    width: 70%;
    margin-left: 15%;
}