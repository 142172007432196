.customer-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.package-modal-section {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
}

.package-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
}

.modal-title-parent {
    display: flex;
    justify-content: left;
}

.modal-title {
    color: #662D91;
    margin-top: 10px;
    margin-right: auto;
    font-weight: 700;
}

.package-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px 20px;
    overflow-y: auto;
    // text-align: center;
    width: 30vw
}

.testmodal {
    display: none;
}

.close-package-modal {
    float: right;
    font-size: 1.2vw;
    font-weight: 500;
    padding: 4px 10.5px;
    border-radius: 4px;
    background-color: #F4F4F4;
}

.close-package-modal:hover {
    cursor: pointer;
    background-color: #f1f1f1;
}

.package-modal-content h2 {
    text-align: center;
}

.proceed-btn {
    padding: 16px 32px;
    width: 84%;
    font-family: "Montserrat";
    border: 1px solid #49B65B;
    background: #49B65B;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    margin-top: 8vh;
    // margin-bottom: 5vh;
}

.proceed-btn:hover {
    cursor: pointer;
    color: #49B65B;
    background-color: white;
    // transition: 0.5s ease all;
}

.header-details {
    display: flex;
    align-items: center;
    gap: 12px;

    a {
        font-size: 13px;
    };
    span {
        color: white;
        padding: 3px 12px;
        border-radius: 50px;
        font-size: 20px;
    }
}

.upgrade-btn {
    padding: 10px 20px;
    font-family: "Montserrat";
    border: 1px solid #49B65B;
    background: #49B65B;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    margin-top: 8vh;
    // margin-bottom: 2vh;
}

.upgrade-btn:hover {
    cursor: pointer;
    color: #49B65B;
    background-color: white;
    // transition: 0.5s ease all;
}

.downgrade-btn {
    padding: 10px 20px;
    font-family: "Montserrat";
    border-radius: 8px;
    font-size: 16px;
    margin-top: 8vh;
    border: 1px solid red;
    color: red;
    background-color: white;
    // margin-bottom: 2vh;
}

.downgrade-btn:hover {
    cursor: pointer;
    background: red;
    color: white;
    // transition: 0.5s ease all;
}