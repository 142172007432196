.toolTipContent {
    .visits {
        color: rgb(218, 123, 194);
    }

    .clicks {
        color: rgb(43, 43, 239);
    }
}
.alignCenter{
    
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.root{
    background: white;
}