.users {
    background-color: #fff;
    padding: 10px 20px 15px 20px;
    border-radius: 8px;
    grid-row: 3/3;
    grid-column: 1/-1;
}

.user-table {
	width: 100%;
	border-collapse: collapse;
}


.user-table td {
	padding: 16px 0px;
	text-align: left;
}
.user-table th{
	text-align: left;
}

.user-row {
	border-bottom:1px solid #D8D8D8;
}

.user-row:last-child {
	border-bottom: none;
}
.invite-users{
	display: flex;;
	justify-content: space-between;
	align-items: center;
}
.edit-button{
	padding: 8px 16px;
    margin-left: 10px;
    border-radius: 8px;
    font-family: 'Montserrat';
    background-color: #662D91;
    color: #fff;
    border: 1px solid #662D91;
    cursor: pointer;
	margin-right:6px;
    font-size: 16px;
	border: 1px solid #662D91;
}
.remove-button{
	padding: 8px 16px;
    margin-left: 10px;
    border-radius: 8px;
    font-family: 'Montserrat';
    font-size: 16px;
    background-color: #fff;
    color: red;
    border: 1px solid red;
    cursor: pointer;
}
.remove-button:hover{
    background-color: red;
    color: #fff;
    transition: all 0.5s ease;
}
.edit-button:hover{
    background-color: #fff;
	border: 1px solid #662D91;
	color: #662D91;
	transition: all 0.5s ease;
}

.edit-button-disable{
    opacity: 0.6;
	padding: 8px 16px;
    margin-left: 10px;
    border-radius: 8px;
    font-family: 'Montserrat';
    background-color: #662D91;
    color: #fff;
    border: 1px solid #662D91;
    cursor: not-allowed;
    font-size: 16px;
	margin-right:6px
}
.remove-button-disable{
    opacity: 0.6;
	padding: 8px 16px;
    margin-left: 10px;
    border-radius: 8px;
    font-family: 'Montserrat';
    background-color: red;
    color: #fff;
    border: 1px solid red;
    font-size: 16px;
    cursor: not-allowed;
}