.select-page {
    // margin-left: -1rem;
}

.select-form {
    width: 505.76px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 5%;
    height: max-content;
    padding-bottom: 13px;
}

.select-text {
	text-align: center;
	color: #662D91;
	font-family: 'Montserrat';
	font-weight: bolder;
	font-size: 25px;
	padding-top: 43px
}

.website-select, .customer-select {
    background: #FFFFFF;
    border: 1px dashed #D8D8D8;
    border-radius: 4px;
    margin: 2% 4%;
    box-sizing: border-box;
    font-size: 22px;
    padding: 5px 10px;
    cursor: pointer;
    
}
.website-select, .customer-select :hover{
    cursor: pointer;
}
.website-icon{
    padding: 1.5px;
    width: 22px;
    height: 22px;
}
.website-names{
    height: 1%;
    padding: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    line-height: 150%;
    color: #1F1F1F;
    display: inline-block;
}
.filteredValues-section{
    background-color: #ffffff;
    position: absolute;
    top: 6.4rem;
    width: 100%;
    border: 1px solid #E6EBED;
    border-radius: 5px;
    max-height: 6rem;
    overflow-y: scroll
};
.filtered_items{
    list-style-type: none;
    padding: 0.4rem 1rem;
}
.filtered_items:hover{
    background-color: #D8D8D8;
    cursor: pointer;
}
.admin--tools {
    width: 330px;
    height: 100px;
    margin-left: auto;
    margin: 40px auto 0px auto;
    background-color: white;
    border: 1px solid #E6EBED;
    position: relative;
    border-radius: 5px;
   
}

.admin--tools_title {
    margin: auto;
    text-align: center;
    padding: 3px 12px;
    display: inline-block;
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    background-color: #5E6172;
    color: #ffffff;
    border-radius: 5px;
}

.admin--tools_form {
    color: #c6c6ca;
    margin-top: 20px;
    margin-bottom: 10px;
     padding: 0px 13px;
}

.admin--tools_heading {
    font-size: 15px;
    margin-bottom: 5px;
}

.admin--tools_input {
    border: solid 1px #ededed;
    border-radius: 5px;
    padding-left: 8px;
    margin-right: 8px;
    margin-top:8px;
    line-height: 25px;
    width: 200px;
    background: #F4F4F4;
    font-family: "Montserrat";
}

.admin--tools_button {
    margin-left: 5px;
    background-color: #5E6172;
    color: #ffffff;
    border-radius: 5px;
    padding: 4px 10px;
    border:none;
    font-family: "Montserrat";
    text-align: center;
}