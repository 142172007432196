.body {
	font-family: 'Montserrat';
	margin: 0;
	padding: 0;
}

.error-message {
	background-color: #ba0000;
	color: white;
	border: 1px solid #ba0000;
	width: 375px;
	margin: auto;
	border-radius: 5px;
	text-align: center;
}

.login-form {
	width: 505.76px;
	margin-left: auto;
	margin-right: auto;
	/* margin-top: 2%; */
	background: #FFFFFF;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
}

.login-text {
	text-align: center;
	color: #662D91;
	font-family: 'Montserrat';
	font-weight: bolder;
	font-size: 25px;
	padding-top: 44px;
	padding-bottom: 20px;
	font-weight: 700;
}

.form {
	margin-left: auto;
	margin-right: auto;
	width: 378.32px;
	margin-top: 20px;
}

.email-label {
	position: absolute;
	width: 43px;
	height: 20px;
	left: 0px;
	top: 0px;
	color: #1F1F1F;
}

.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

label {
	margin-bottom: 5px;
	position: relative;
	font-size: 13px;
}

.login-inputs {
	background: #F4F4F4;
	border-radius: 12px;
	line-height: 40px;
	border: none;
	padding-left: 14px;
	font-family: "Montserrat";
	width: 359px;
	outline: none;
}

.submit-button-parent {
	width: 100%;
	display: flex;
	justify-content: center;
}

.submit-button {
	background: #49B65B;
	border-radius: 8px;
	width: 240px;
	height: 52px;
	border: none;
	color: rgba(255, 255, 255, 1);
	font-size: 19px;
	font-weight: bold;
	margin-top: 50px;
	cursor: pointer;
	font-family: "Montserrat";
}

.submit-button:hover {
	background-color: rgb(71, 203, 71);
}

.forgotpassword-text {
	padding-bottom: 25px;
	text-decoration-line: underline;
	color: #1F1F1F;
	text-align: center;
	font-size: 13px;
	margin-top: 25px;
}

.input-with-icon {
	position: relative;
	width: 350px
}

.input-icon {
	position: absolute;
	top: 50%;
	right: -18px;
	transform: translateY(-50%);
	cursor: pointer;
}