.bot-type-heading {
	font-weight: 800;
	font-size: 25px;
	margin-top: 5px;
}

.modals-section{
	display: grid;
	gap: 19px;
	grid-template-columns: repeat(3, 1fr);
	margin-top:15px
}
.bot-classification-modals {
	position: relative;
    padding: 10px 10px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 7px;

	div:nth-child(1){
		font-weight: bold;
		font-size: larger;
	}
	div:nth-child(2){
		white-space: pre-wrap;
	}
	div:nth-child(3){
		margin-left: auto;
		margin-top: auto;		
	}
}
.bot-classification-section{
	grid-column: 1/-1;
	overflow: auto;
	padding: 10px 0px;
    margin-bottom: 60px;

	
}