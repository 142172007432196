.card-container{
    border-radius: 1rem;
    border: 2px solid;
    width:15rem;
    position: relative;
    .bot-title{
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
        padding: 0.6rem;
        color: white;
    }
    .bot-dangerous-level{
        padding:0.8rem;
        color: white;
        width: fit-content;
        border-bottom-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
        font-size: 0.8rem;
        position: absolute;
        bottom:0px
    }
    .bot-total-value{
        font-weight: bolder;
        font-size: 3rem;
    }
    .bot-spec{
        padding: 0.5rem;
        margin-bottom: 3rem;
    }
    .bot-percentage{
        font-weight:bold ;
    }
    .targeted-bots{
        margin-top: 1rem;
        font-size: 0.7rem;
    }
    .bots-url{
        font-size: 0.7rem;
        font-weight: bolder;
        margin-bottom: 1rem;
    }
}