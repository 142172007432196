.alert-section{
    // position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: solid 1px red;
    background: pink;
    border-radius: 10px;
    margin-top: 1rem;
    width: 95%;
    z-index: 2;

    div{
        font-weight: bolder;
        margin-bottom: 0.5rem;
    }
}