.linegraph-container{
	grid-column: 4/-1;
	background-color: #fff;
	border-radius: 8px;
	grid-row: 1/3;
    padding: 7px 7px 15px 0;
    font-size: 18px;
    font-weight: 500;
	position: relative;

    .tooltip {
        margin: 7px 7px 0 0;
    }

	.max-icon {
        margin: 7px 7px 0 0;
	}
}
.toolTipContent{
	cursor: pointer;
	padding:6px 13px 6px 13px;
	gap:4px;
	text-transform: capitalize;
	text-align: center;
	border-radius: 8px;
	background-color: #fff;
	box-sizing: border-box;
	box-shadow: 0px 0px 6px 0px rgba(31, 31, 31, 0.2);
}
.humansValues{
	color:rgba(102, 45, 145, 1);
	font-weight: bolder;
}
.goodBotsValues{
	color: #49B65B;
	font-weight: bolder;
}
.botsValues{
	color:rgba(226, 41, 55, 1);
	font-weight: bolder;
}
.evaluating{
	color: rgba(164, 128, 189, 1);
	
}
.toolTipContent div{
	margin-top:7px;
}
.finishedSessions-section{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	font-size: 1vw;
	font-weight: bold;
	margin-top: 1rem;
	.humansValues{
		color:rgba(102, 45, 145, 1);
		font-size: 2vw;
		font-weight: bolder;
	}
	.goodBotsValues{
		font-size: 2vw;
		color: #49B65B;
		font-weight: bolder;
	}
	.botsValues{
		color:rgba(226, 41, 55, 1);
		font-size: 2vw;
		font-weight: bolder;
	}
	div{
		display: flex;
		flex-direction: column;
		gap:0.3rem
	}
}