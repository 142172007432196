.pagination {
    display:flex;
    margin:10px 0px 5px 0px;
    gap: 6px
  }
  
  .pagination div {
    color: black;
    float: left;
    padding: 8px 16px;
    cursor: pointer;
  }
  .decrement-button {
    color: black;
    float: left;
    padding: 8px 16px;
    cursor: pointer;
    border:none;
    background: none;
  }
  .decrement-button:hover{
    background-color: #ddd;
    border-radius: 50%;
  }
  
  .decrement-button-disabled{
    color: black;
    float: left;
    padding: 8px 16px;
    /* cursor: not-allowed; */
    border:none;
    background: none;
    opacity: 0.4;
  }
  .pagination div{
    transition: background-color .3s;
    border-radius: 50%;
  }
  
  .pagination div.active {
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
  }
  
  .pagination div:hover:not(.active) {
    background-color: #ddd;
    border-radius: 50%;
  }
  /* .pagination button:hover:not(.active) {
    background-color: #ddd;
    border-radius: 50%;
  } */