.region-content {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 22px;
}

.main-region {
	display: flex;
}

@media(max-width:1300px) {
	.main-region {
		display: block;
	}
	.save-change{
		float: left !important;
	}
	.date-time{
		margin-left: 0 !important;
	}
}
@media(max-width:700px){
	.week-days,
	.time-format,
	.date-format{
		display: block !important;
	}
}

.week-days,
.time-format,
.date-format {
	display: flex;
	font-size: 14px;
	gap: 20px;
	margin-top: 4px;
	margin-bottom: 8px;
}

.save-change {
	float: right;
	background-color: #662D91;
	padding: 13px 16px;
	color: #fff;
	border-radius: 8px;
	cursor: pointer;
	font-family: "Montserrat";
	font-size: 16px;
	border: 1px solid #662D91;
	margin-top: 14px;
	width:162px;

	// &:not(:disabled):hover {
	// 	background-color: #fff;
	// 	border: 1px solid #662D91;
	// 	color: #662D91;
	// 	transition: all 0.5s ease
	// }
}

.save-change:disabled {
	opacity: 0.6;
	float: right;
	background-color: #F4F4F4;
	padding: 13px 16px;
	color: #D8D8D8;
	border-radius: 8px;
	font-family: "Montserrat";
	font-size: 16px;
	border: 1px solid #F4F4F4;
	margin-top: 14px;
	cursor: not-allowed;
}

// .save-change:hover {
// 	background-color: #fff;
// 	border: 1px solid #662D91;
// 	color: #662D91;
// 	transition: all 0.5s ease;
// }

.language-input,
.timezone-input {
	box-sizing: border-box;
	background: #F4F4F4;
	border-radius: 12px;
	height: 40px;
	border: none;
	padding-left: 14px;
	font-family: "Montserrat";
	width: 528px;
	margin: 7px 0px;
	align-items: center;
	/* Center the default value */
	padding: 0 12px;
}

.date-time {
	margin-left: 23px
}

.labels {
	padding-left: 6px;
	padding-top: 3px;
}

.datalist {
	cursor: pointer;
}