.value-card {
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 0px #e3e2e2;
    padding: 1.5em;
    margin-top: 20px;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    width: 15vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 150px;
    &.small{
        min-height: 50px;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
        .title-value {
            color: rgb(3, 0, 152);
            font-weight: bold;
            font-size: 1.2vw;
            text-wrap: nowrap;
        }
        .change-denoter-label {
            color: red;
        }

    }

    .image {
        width: 150px;
    }
    .sub-title{
        font-size: 1vw;
    }
}