.blocked-threat-level{
    grid-column: 7/-1;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px
}
.bot-intentions-container {
    grid-column: 5/9;
    position: relative;
    .bot-intentions-classification{
        overflow-y: scroll;
        height: 65%;
      }
}
.bot-type-breakdown{
    grid-column: 1/-1;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px;
    .bot-details{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap:0.5rem;
        border: none;
        padding: 0.5rem 0.5rem 0.5rem 1rem
    }
}
.radial-legend-section{
    position: absolute;
    top: 6rem;
    right: 2rem;
    display: flex
;
    gap: 0.2rem;
    flex-direction: column;
}