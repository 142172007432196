.company-name {
	font-weight: 800;
	font-size: 25px;
    margin-top: 5px;
}
.customer-main-content{
    grid-column: 1/-1;
    overflow: auto;
    padding: 10px 0px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    /* grid-template-rows: 35px 632px 180px 250px 280px; */
    gap: 19px;
    margin-bottom: 1%;
}
.website-table{
	width: 100%;
    border-collapse: collapse;
}

.website-table td{
    white-space-collapse: preserve-breaks;
    padding: 16px 0px;
    text-align: left;
};

.website-table th{
    white-space-collapse: preserve-breaks;
}