.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: calc(30.33vh - 40px) calc(30.33vh - 40px);
    height: 100%;
    width: 100%;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding-bottom: 2em;
    .graph{
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    .bitem-1{
        grid-row: 1/ span 2;
        grid-column: 1/3;
    }
    .bitem-2{
        grid-column: 1;
        grid-row: 2;
    }
    .bitem-3{
        grid-row: 1/ span 2;
        grid-column: 3/9;
        border-radius: 10px;
    }
    .bitem-4{
        grid-row: 4;
        grid-column: 1/9;
    }
    .bitem-5{
        grid-row: 3;
        grid-column: 1/9;
        display: flex;
        gap:20px;
        justify-content: space-between;
    }
}
.adNetworkHeading{
    display: flex;
    color: white;
    gap: 10px;
    padding: 8px 16px;
}
