.afp-section {
    background-color: #fff;
    padding: 10px 20px 15px 20px;
    border-radius: 8px;
    grid-column: 1/1;
}

.modal-login-form {
    width: 505.76px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 2%; */
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 22px 22px;
    position: relative;
}

.connection-permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-login-text {
    text-align: center;
    color: #662D91;
    font-family: "Montserrat";
    font-weight: bolder;
    font-size: 25px;
    margin-top: 10px;
}

.connect-afp-button {
    padding: 8px 16px;
    // margin-left: 10px;
    border-radius: 8px;
    font-family: 'Montserrat';
    background-color: #662D91;
    color: #fff;
    border: 1px solid #662D91;
    cursor: pointer;
    // margin-right: 6px;
    position: relative;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;

    &.loading{
        > .label{
            opacity:.5;
        }
        &::after {

        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        // top: 5px;
        // // left: 54px;
        // // right: 0;
        // bottom: 0;
        // // margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }}
}

.connect-afp-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 15px 20px 19px rgba(84, 84, 87, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

.close-afp-modal {
    position: absolute;
    right: 10px;
	font-size: 1.2vw;
	font-weight: 500;
	padding: 4px 10.5px;
	border-radius: 4px;
    margin: 0;
    // margin-left: 20px;
    margin-top: -8px;
    background-color: #F4F4F4;
}

.close-afp-modal:hover {
	cursor: pointer;
	background-color: #f1f1f1;
}

.status-section {
    // text-align: center;
    align-items: center;
    display: flex;
	flex-direction: column;
    width: 100%;
}

.status-section-title {
    color: #662D91;
    width: 100%;
    font-weight: 700;
    margin-top: 0;
}

.status-section-text {
    width: 100%;
    font-weight: 500;
}

.status-button {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: end;
}

.status-section div button:nth-child(1) {
    padding: 8px 16px;
    width: 90px;
    margin-right: 12px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid black;
    background-color: #fff;
    color: black;
    font-family: "Montserrat";
    cursor: pointer;
    float: right;
    // margin-top: 15px;
}
.status-section div button:nth-child(1):hover{
	background-color: black;
	color: #fff;
}

.status-section div button:nth-child(2) {
    padding: 8px 16px;
	// width: 70px;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid #662D91;
	background-color: #662D91;
	color: #fff;
	font-family: "Montserrat";
	cursor: pointer;
	float: right;
	// margin-top: 15px
}
.status-section div button:nth-child(2):hover{
    background-color: #fff;
	color: #662D91;
}

.status-section h1 {
    text-align: center;
    color: #662D91;
    font-family: 'Montserrat';
    font-weight: bolder;
    font-size: 25px;
    padding-top: 43px;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}