.date-tables {
    width: 90%;
    border-spacing: 0px 0.4vh;
    height: 50%;
    justify-self: center;
}

.date-picker-table-th{
	border: none;
	text-align: center;
	font-size: 0.75vw;
	padding: 0;
	width: 1.3vw;
	margin: 0;
	color: #5A5A5A;
}

.date-picker-table-tr{
	border: none;
	
}

.selected-end{
	color: white;
	background-color:  #D1C0DE;	
	border-radius: 0px 8px 8px 0px !important;
	padding: 0;
}

.selected-end > span{
    background-color: #662D91;
    border-radius: 8px 8px 8px 8px !important;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
} 

.selected-start{
	color: white;
	border-radius: 8px 0px 0px 8px !important;
	background-color:  #D1C0DE;
	padding: 0;
}

.selected-start > span{
    background-color: #662D91;
    border-radius: 8px 8px 8px 8px !important;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
} 

.between-date{
	color: #662D91;
	background-color:  #D1C0DE;
}


.date-table-month-title{
    display: grid;
    grid-template-columns: 20% 60% 20%;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #F4F4F4;
	font-size: 1.25vw;
}

.unknown{	
	min-height: 90%;
	width: 90%;
	display: grid;
    grid-template-rows: 20% 80%;
}

.change-months-button{
    border-radius: 50%;
    border: none;
    width: 2vw;
    height: 2vw;
    padding: 0px;
    background-color: #F4F4F4;
    display: flex;
    /* justify-items: center; */
    justify-content: center;
    align-items: center;
	cursor: pointer;
}

.change-months-button svg{
	/* fill: black; */
	height: 50%;
	width: 50%;
}

.table-area{
    display: flex;
    justify-items: center;
    grid-template-columns: 35% 35% 30%;
    height: 100%;
    width: 90%;
}
.calendarBody{
    height: 45%;
    width: 40%;
    background-color: white;
    place-self: center;
    border-radius: 10px;
    /* padding: 2%; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.date-picker-sidebar{
	background-color: #F4F4F4 ;
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	
}

.normal-date , .selected-end, .selected-start, .between-date{
	border: none;
	padding: 0;
	text-align: center;
	/* width: 3vw; */
	overflow: hidden;
	font-size: 0.75vw;
	height: 4vh;
	cursor: pointer;
}
.normal-date--exceeds{
	color: #f6f6f7;
    cursor: default;
}
.normal-date--notwithin{
	opacity: 0.4;
}
.date-picker-table-tr :last-child{
	border-radius: 0% 8px 8px 0%;
}

.date-picker-table-tr :first-child{
	border-radius: 8px 0px 0px 8px;
}
.date-picker-button-box{
	display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}
.date-picker-confirm{
    display: flex;
    /* width: 80%; */
    border-radius: 0.5rem;
    color: white;
    background-color: #662D91;
    font-size: 0.85vw;
    font-weight: 400;
    font-family: 'Montserrat';
    font-style: normal;
    border: none;
    cursor: pointer;
    height: 50%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    align-items: center;
    padding: 2.5% 4%;
}

.date-picker-confirm:hover{
	background-color: white;
	color: #662D91;
	outline: #662D91 solid 1px;
	box-sizing: border-box;
}
.date-picker-button-container{
    display: flex;
    width: 90%;
    justify-content: flex-end;
    align-items: center;
}

/* .date-picker-cancel{
    display: flex;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 5px;
    color: white;
    background-color: #E22937;
    font-weight: 400;
    font-family: 'Montserrat';
    font-style: normal;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 2%;
    top: 2%;
}

.date-picker-cancel:hover{
	background-color: white;
	color: #E22937;
	outline: #E22937 solid 1px;
	box-sizing: border-box;
} */

.date-picker-sidebar-form{
	display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 4% 2%;
	justify-content: space-between;
}
.date-picker-input-area{
	display: flex;
	width: 90%;
	flex-direction: row;
	justify-content: space-around;
}
.date-picker-input-container{
	width: 45%;
}

.date-picker-date-input{
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin-top: 5%;
	border: none;
	border-radius: 8px;
	text-align: center;
	font-size: 0.8vw;
}

.date-picker-date-label{
	color: #5A5A5A;
	font-size: 0.9vw;
	font-weight: 400;
	font-family: 'Montserrat';
	font-style: normal;
}

.date-picker-dropdown{
	background-color: white;
	width: 100%;
	border-radius: 8px;
	position: absolute;
}
.date-picker-dropdown:hover {
	cursor: pointer;
}
.date-picker-dropdown--active{
	display: flow-root;
}

.date-picker-dropdown-area{
	display: flex;
    flex-direction: column;
    width: 90%;
	height: 60%;
}

.dropdown-container{
	position: relative;
	z-index: 2;
}
.background-shader{
	background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    right: 0px;
    top: 0px;
    cursor: default;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.date-picker-inputs-container{
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    /* height: 40%; */
    box-sizing: border-box;
    flex-wrap: nowrap;
}