.linegraph-container.CO2 {
	grid-column: 3/-1;
	background-color: transparent;
	border-radius: 8px;
	grid-row: 1/3;
	padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
	position: relative;
	// height: 32vh;
	width: 100%;
	margin: auto;
	margin-bottom: -10px;
}

.toolTipContent{
	cursor: pointer;
	padding:6px 13px 6px 13px;
	gap:4px;
	text-align: center;
	border-radius: 8px;
	background-color: #fff;
	box-sizing: border-box;
	box-shadow: 0px 0px 6px 0px rgba(31, 31, 31, 0.2);
}
.humansValues{
	color:rgba(102, 45, 145, 1);
}
// .botsValues{
// 	color:#6EBA7A;
// }

.evaluating{
	color: rgba(164, 128, 189, 1);
}
.toolTipContent div{
	margin-top:7px;
}