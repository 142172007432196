.content-active {
	display: grid;
    transform: all 0.5s ease;
    margin-left: auto;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 15px;
    grid-template-columns: repeat(7, 1fr);
    transition: all 0.5s ease;
}
/* @media (max-width:1300px){
.content{
	width:70% !important;
	display: grid;
	margin-left: auto;
	margin-left: 8%;
	margin-top: 4.5%;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: 100vh;
	transition: all 0.5s ease;
}
} */

.content {
	display: grid;
	width: 90%;
	margin-left: auto;
	margin-left: 8%;
	margin-top: 4.5%;
	grid-template-columns: repeat(7, 1fr);
	transition: all 0.5s ease;
}

.side-bar {
	grid-column: 1/3;
	border: 1px solid #ffffff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 8px;
	height: max-content;
}

.main-content {
    grid-column: 1/-1;
    overflow-y: auto;
	overflow-x: hidden;
    padding: 10px 0px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* grid-template-rows: 35px 368px 227px 310px 270px; */
    gap: 19px;
    margin-bottom: 6%;
}

.myaccount {
	color: rgba(0, 0, 0, 1);
    font-weight: 800;
    font-size: 25px;
    margin-top: 5px;
    grid-row: 1/2;
}

.sidebar-content {
	padding-top: 13px;
	padding-left: 21px
}


